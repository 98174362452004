import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { getTeam, getTeamMembers, getPlayers, updateUser } from "./firebaseData";
import "./TeamInfo.css";

function TeamInfo(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teamMembers, setTeamMembers] = useState('');
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerSearch, setPlayerSearch] = useState('');
  const [playersByID, setPlayersByID] = useState([]);
  const [hasNoTeam, setHasNoTeam] = useState(false);
  const [showSavePlayersAlert, setShowSavePlayersAlert] = useState(false);
  const addPlayersModalRef = useRef();


  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      setTeam(await getTeam(userDoc.displayTeam));
      setTeamMembers(await getTeamMembers(userDoc.displayTeam));

      let playerList = await getPlayers();
      let playerListByID = {};
      playerList.forEach(thePlayer =>{
        playerListByID[thePlayer.id] = thePlayer;
      });
      setPlayers(playerList);
      setFilteredPlayers(playerList);
      setPlayersByID(playerListByID);
  

    } else {
      setHasNoTeam(true);
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    fetchData();
    console.log(window);

  }, [user, loading]);

  const handlePlayerSearchChange = ((event) => {
    let searchText = event.target.value; 
    let searchExp = new RegExp(searchText, "i");
    setPlayerSearch(searchText)
    
    if (searchText.length > 0) {
      setFilteredPlayers(players.filter((player) => {
        return player.lastName.match(searchExp) || player.firstName.match(searchExp);
      }));    
    } else {
      setFilteredPlayers(players);
    }
  });


  const handleAddPlayer = () => {
    console.log("Add player...");
    setPlayerSearch("");
    setFilteredPlayers(players);

    var elements = document.getElementById("playerMultiSelect").options;

    for(var i = 0; i < elements.length; i++){
      elements[i].selected = false;
    }


    const addPlayersModal = addPlayersModalRef.current;
    const bsModal = new window.bootstrap.Modal(addPlayersModal);
    bsModal.show();    

  }

  const handleSavePlayerSelection = async() => {
    console.log("Save players to team...");
    let selectedTeamID = team.id;

    setShowSavePlayersAlert(true);

    let playerMultiSelect = document.getElementById("playerMultiSelect");
    let selectedPlayers = playerMultiSelect.selectedOptions;
    for (let i = 0; i < selectedPlayers.length; i++){
      console.log("Selected Player:", selectedPlayers[i].value, selectedPlayers[i].label);
      let selectedPlayer = playersByID[selectedPlayers[i].value];
      if(!selectedPlayer.registeredTeams.includes(selectedTeamID)){
        selectedPlayer.registeredTeams.push(selectedTeamID)
      } 
      if(!selectedPlayer.teams.includes(selectedTeamID)){
        selectedPlayer.teams.push(selectedTeamID)
      } 
      
      await updateUser(selectedPlayer);
    }

    fetchData();

    let timeout = setTimeout(() => {
      setShowSavePlayersAlert(false);
      const addPlayersModal = addPlayersModalRef.current;
      const bsModal = window.bootstrap.Modal.GetInstance(addPlayersModal);
      bsModal.hide();   
    }, 2000);

  }

  const handleRemovePlayerFromTeam = async(teamMember, event)=>{
    console.log("Remove Player from Team...", teamMember);
    if (window.confirm("Are you sure you want to remove " + teamMember.firstName + " " + teamMember.lastName + " from this team?")){
      console.log("Removing...");
      let teamsIndex = teamMember.teams.indexOf(team.id);
      if(teamsIndex !== -1){
        let playerTeams = [...teamMember.teams];
        playerTeams.splice(teamsIndex, 1);
        teamMember.teams = playerTeams;
      }

      let registeredTeamsIndex = teamMember.registeredTeams.indexOf(team.id);
      if(registeredTeamsIndex !== -1){
        let playerRegisteredTeams = [...teamMember.registeredTeams];
        playerRegisteredTeams.splice(registeredTeamsIndex, 1);
        teamMember.registeredTeams = playerRegisteredTeams;
      }

      if(teamMember.displayTeam === team.id){
        if(teamMember.teams.length > 0){
          teamMember.displayTeam = teamMember.teams[teamMember.teams.length -1];
        } else {
          teamMember.displayTeam = '';
        }
      }
      await updateUser(teamMember);
      await fetchData();
    }

  }

  const changeDisplayTeam = async(teamMember, newDisplayTeamID) => {
    console.log("Change Display Team: ", teamMember, newDisplayTeamID);
    teamMember.displayTeam = newDisplayTeamID;
    await updateUser(teamMember);
    await fetchData();
  }

  //console.log('Rendering Dashboard...');
  let title = "Team Roster"; //team.teamTitle;
  let activeNav = "teamInfo";
  let link = "/roster";
  let emptyMessage = 'No Teams. Please join one...';

  let emailList = [];
  let phoneList = [];

  if (teamMembers) {
    teamMembers.players.forEach((player) => {
        emailList.push(player.emailAddress);
        phoneList.push(player.mobilePhone);
    });
  }


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                    {hasNoTeam && 
                      <div>
                      <h3 className="mt-10 mb-0 text-center">You are not registered with a team...</h3>
                      <h4 className="mb-10 mt-4 text-center text-muted">Please contact your captain and be sure you registered <br />with the same email address you use for your USTA account.</h4>
                      </div>
                    }


                    {!hasNoTeam && !team && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    {!hasNoTeam && team &&                     
                      <div>
                        <h3>{ team.teamTitle } - Team Roster</h3>
                        <h5 className="mb-2 text-muted border-top border-bottom py-2">
                          <strong>USTA Team Number</strong>: <a target="_blank" rel="noopener noreferrer" href={team.ustaLink}>{ team.id }</a> [click to open USTA team page]
                        </h5>
                        <div className="row">
                          <div className="col">
                            <div className="row text-black">
                              <div className="col"><strong>Season</strong>: { team.year } { team.seasonName } { team.type } { team.ntrp } { team.age }</div>
                            </div>   
                            <div className="row text-black">
                              <div className="col"><strong>Team Name</strong>: { team.teamName }</div>
                            </div>   
                            <div className="row text-black">
                              <div className="col"><strong>Address</strong>: { team.address }</div>
                            </div>   
                          </div>                   
                        </div>

                        <div className="my-5">
                        { teamMembers && 
                          <h5 className="clearfix">Team Members ({teamMembers.players.length})
                              { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                <a className="ms-2 link-primary" name="addPlayer" id="addPlayer" onClick={handleAddPlayer} title="Add Practice" alt="Add Player"><i className="bi bi-plus-circle-fill"></i></a>
                              }                              
                              <span className="ms-2">
                              <a className="btn btn-sm btn-outline-secondary text-uppercase float-end" href={"mailto:" + emailList.join(",")} target="_blank" rel="noopener noreferrer"><i className="bi bi-reply-all-fill me-2"></i>Email All</a>
                              </span>

                          </h5>
                        }
                        <div className="row row-cols-md-2 text-black">
                        {teamMembers && teamMembers.players && teamMembers.players.length > 0 && teamMembers.players.map(teamMember => (
                          <div className="col" key={teamMember.id}>
                          <div className="card my-2">
                            <div className="card-body">
                              <div className="card-title clearfix">
                                { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                <a title="Remove Player from Team" alt="Remove Player from Team" className="float-end link-dark ms-2" onClick={handleRemovePlayerFromTeam.bind(this, teamMember)}><i className="bi bi-trash-fill"></i></a>
                                }
                                <a title="Add Contact" alt="Add Contact" className="float-end" href={"https://us-central1-tennis-team-manager.cloudfunctions.net/createVCard?fn=" + teamMember.firstName + "&ln=" + teamMember.lastName + "&email=" + teamMember.emailAddress + "&tel=" + teamMember.mobilePhone} target="_blank" rel="noopener noreferrer"><i className="bi bi-person-rolodex text-black"></i></a>
                                {teamMember.firstName} {teamMember.lastName}

                                { (teamMember.captainsTeam && teamMember.captainsTeam.length > 0 &&  teamMember.captainsTeam.indexOf(team.id) !== -1)
                                  ? <i className="bi bi-person-badge ms-1" alt="Team Captain" title="Team Captain"></i>
                                  : ""
                                }
                              </div>

                              <div className="card-text">
                                <div className="small">
                                  <a href={"tel:" + teamMember.mobilePhone}>{teamMember.mobilePhone}</a>
                                </div>
                                <div className="small">
                                  <a href={"mailto:" + teamMember.emailAddress}>{teamMember.emailAddress}</a>
                                </div>
                                <div className="small">
                                  NTRP: {teamMember.ntrp}
                                </div>
                                { userInfo.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2' &&                                
                                <div className="xsmall">
                                  <div>UID: {teamMember.id}</div>
                                  <div>Display Team: ID: {teamMember.displayTeam}</div>

                                  <select className="form-select lineup-select" value={teamMember.displayTeam} onChange={(e) => changeDisplayTeam(teamMember, e.target.value)}>
                                  <option value=''>Select One...</option>
                                    { teamMember.registeredTeams.map((theTeam, index) => (
                                      <option key={teamMember.id + '-' + theTeam + '-' + index} value={theTeam}>{theTeam}</option>
                                    ))}
                                  </select>
                                  
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          </div>
                        ))}
                        </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>

      { players.length > 0 &&                    
      <div className="modal fade" id="addPlayers" tabIndex="-1" aria-labelledby="addPlayersLabel" aria-hidden="true" ref={addPlayersModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addPlayersLabel">Add Player(s)</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">                  
              <input type="text" className="form-control" value={playerSearch} onChange={handlePlayerSearchChange} placeholder="Find player by first or last name..." />
              <div className="mb-3">
                <label htmlFor="playerMultiSelect"  className="form-label me-2 fw-bold">Select One or More Player: </label>
                <select className="form-select" multiple={true} size={5} aria-label="Player Select"  id="playerMultiSelect" name="playerMultiSelect">
                  {filteredPlayers.map(thePlayer => (
                    <option value={thePlayer.id} key={thePlayer.id}>{thePlayer.firstName} {thePlayer.lastName}</option>
                  ))}
                </select>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSavePlayerSelection}>Add Selected Player(s)</button>
            </div>
          </div>
        </div>
      
        { showSavePlayersAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="savePracticeToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>
      }

      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default TeamInfo;