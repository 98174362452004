import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, sendPracticeAvailabilityEmail, signInWithCustomToken } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { nextTuesday, nextWednesday } from "date-fns";
import { dateStringForTimeZone, timeStringForTimeZone, dayOfWeekForTimeZone, getTeam, getTeamMembers, getPractices, setPracticeAvailability, addPractice, deletePractice } from "./firebaseData";
//import { Timestamp } from "firebase/firestore";
import "./Practices.css";

function Practices(props) {
  var defaultPracticeDate = new Date();
  defaultPracticeDate.setHours(18);
  defaultPracticeDate.setMinutes(0);
  defaultPracticeDate.setSeconds(0);
  //defaultPracticeDate = nextTuesday(defaultPracticeDate);
  defaultPracticeDate = nextWednesday(defaultPracticeDate);
  
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teamMembers, setTeamMembers] = useState('');
  const [practices, setPractices] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showSavePracticeAlert, setShowSavePracticeAlert] = useState(false);
  const [practiceDate, setPracticeDate] = useState(defaultPracticeDate);
  const [hasNoTeam, setHasNoTeam] = useState(false);
  const addPracticeModalRef = useRef();
  const params = useParams();

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      var thisTeam = await getTeam(userDoc.displayTeam);
      var theseMembers = await getTeamMembers(userDoc.displayTeam);
      setPractices(await getPractices(thisTeam, theseMembers));
      setTeam(thisTeam);
      setTeamMembers(theseMembers);
    } else {
      setHasNoTeam(true);
    }
}

  useEffect(() => {
    if (loading) return;
    if (!user) {
    
      if(params.token){
        console.log("Logging User in with token...", params.token);

        signInWithCustomToken(auth, params.token)
          .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
            window.open("/practices/", "_self");
            //fetchData();
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Error logging in with token: ", errorCode, errorMessage);
            //Redirect to login page...
            return navigate("/login");
          });

      } else {
        return navigate("/login");
      }
    } else {
      console.log('User UID: ', user.uid);
      fetchData();
    }
    

  }, [user, loading]);

  const handleAvailableClick = (async(practice, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(practice);
    console.log(userInfo);
    await setPracticeAvailability(practice, userInfo, true);
    setPractices(await getPractices(team, teamMembers));
  });

  const handleUnavailableClick = (async(practice, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(practice);
    console.log(userInfo);
    await setPracticeAvailability(practice, userInfo, false);
    setPractices(await getPractices(team, teamMembers));
  });

  const handleSendPracticeAvailabilityClick = (async(practice, event) => {
    console.log("Request Practice Availability Clicked...");
    var testMode = false;

    if(testMode === false){
      if(!window.confirm("Send practice availability email to everyone on the team?")){
        return(false);
      }
    }

    // Display update message...
    setShowAlert(true);

    sendPracticeAvailabilityEmail({practice: practice, team: team, teamMembers: teamMembers, test: testMode}).then((result) => {
      console.log("Queued up emails requesting Practice Availability...");
    });

    let timeout = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

  });

  const handleAddPractice = (async(event) => {
    console.log("Add practice clicked!");

    const addPracticeModal = addPracticeModalRef.current;
    const bsModal = new window.bootstrap.Modal(addPracticeModal);
    bsModal.show();    

  });

  const handleSavePractice = (async(event) =>{
    console.log("Save New Practice...");
    
    const newPractice = { 
      time: practiceDate, 
      available: [], 
      unavailable: [],
      practiceLocation: document.getElementById("practiceLocation").value,
      practiceType: document.getElementById("practiceType").value,
      minPlayers: parseInt(document.getElementById("minPlayers").value),
      maxPlayers: parseInt(document.getElementById("maxPlayers").value),
      teamID: team.id,
      localDateString: dateStringForTimeZone(practiceDate, 'America/Los_Angeles'),
      localTimeString: timeStringForTimeZone(practiceDate, 'America/Los_Angeles'),
    }

    // Display update message...
    setShowSavePracticeAlert(true);

    //Add practice to firestore...
    await addPractice(newPractice);
    fetchData();

    let timeout = setTimeout(() => {
      setShowSavePracticeAlert(false);
      const addPracticeModal = addPracticeModalRef.current;
      const bsModal = window.bootstrap.Modal.GetInstance(addPracticeModal);
      bsModal.hide();   
      }, 2000);
    

  });

  const handleDeletePracticeClick = (async(practice, event) => {
    console.log("Delete practice: ", practice);
    await deletePractice(practice.id);
    setPractices(await getPractices(team, teamMembers));
  });

  //console.log('Rendering Matches...');
  let title = "Practices";
  let activeNav = "practices";
  let link = "/practices";
  let emptyMessage = 'No practices found...';

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">

                {hasNoTeam && 
                    <div>
                    <h3 className="mt-10 mb-0 text-center">You are not registered with a team...</h3>
                    <h4 className="mb-10 mt-4 text-center text-muted">Please contact your captain and be sure you registered <br />with the same email address you use for your USTA account.</h4>
                    </div>
                 }



                  {!hasNoTeam && !team && 
                  <div className="card-body">
                    <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    </div>
                  }
                  { !hasNoTeam && team &&                     
                    <div className="card-body">
                      <h3 className="card-title">{ team.teamTitle } - Practices</h3>
                      <h6 className="card-subtitle mb-2 text-muted border-top border-bottom py-2">
                        <strong>USTA Team Number</strong>: <a target="_blank" rel="noopener noreferrer" href={team.ustaLink}>{ team.id }</a> [click to open USTA team page]
                      </h6>
                      

                      <div className="my-5">
                      { practices && 
                        
                        <h5 className="clearfix">Practices ({practices.length})
                        { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                          <a className="ms-2 link-primary" name="addPractice" id="addPractice" onClick={handleAddPractice} title="Add Practice" alt="Add Practice"><i className="bi bi-plus-circle-fill"></i></a>
                        }
                        </h5>
                      }
                      <div className="row row-cols-md-1">
                      { !practices && 
                        <div className="text-center">
                            <h2>Loading...</h2>
                              <Rings
                              height="180"
                              width="180"
                              color="#0000ff"
                              radius="6"
                              wrapperStyle={{display:"block"}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="rings-loading"
                            />
                        </div>
                      }

                      {practices && practices.map(practice => (
                        <div className="col" key={practice.id}>
                          <div className={ practice.isOld === false ? "card my-2" : "card my-2 bg-secondary" }>
                          
                          <div className="card-body">
                            <div className="card-title text-black clearfix">
                            { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                              <button type="button" className="btn btn-sm btn-outline-dark float-end" onClick={handleDeletePracticeClick.bind(this, practice)}><i className="bi bi-trash-fill me-2"></i>Delete</button>
                            }
                              <strong>Date</strong>: { dateStringForTimeZone(practice.time.toDate(), "America/Los_Angeles") } ({dayOfWeekForTimeZone(practice.time.toDate(), "America/Los_Angeles")})<br />
                              <strong>Start Time</strong>: { timeStringForTimeZone(practice.time.toDate(), "America/Los_Angeles") } <br />
                              <strong>Location</strong>: {practice.practiceLocation} <br />
                              <strong>Type</strong>: {practice.practiceType} <br />
                              <strong>Players Needed</strong>: {practice.playersNeeded} <br />
                              <strong>Max Players</strong>: {practice.maxPlayers} <br />

                            </div>

                            <div className="card-text text-black">
                              <strong>Available Players {practice.availablePlayers ? "(" + practice.availablePlayers.length + ")" : "(0)"}</strong>:<br />
                              {practice.availablePlayers && practice.availablePlayers.map((player, index) => (
                                <span key={player.uid}>{player.firstName} {player.lastName}{ index+1 < practice.availablePlayers.length ? ',' : '' }&nbsp;</span>
                              ))}
                              <br />
                              <br />
                              <strong>Unavailable Players {practice.unavailablePlayers ? "(" + practice.unavailablePlayers.length + ")" : "(0)"}</strong>:<br />
                              {practice.unavailablePlayers && practice.unavailablePlayers.map((player, index) => (
                                <span key={player.uid}>{player.firstName} {player.lastName}{ index+1 < practice.unavailablePlayers.length ? ',' : '' }&nbsp;</span>
                              ))}
                              <br />
                              <br />
                              <strong>No Response ({practice.noReplyPlayers.length})</strong>:<br />
                              {practice.noReplyPlayers.map((player, index) => (
                                practice.unavailablePlayers.concat(practice.availablePlayers).includes(player) === false &&
                                <span key={player.uid}>{player.firstName} {player.lastName}{ index+1 < practice.noReplyPlayers.length ? ',' : '' }&nbsp;</span>
                              ))}
                              <br />
                              <br />

                              { (practice.isOld === false) &&
                              <div className="clearfix">
                                <div className="small text-muted">Please click Available or Unavailable</div>
                                <button type="button" className="btn btn-outline-success me-2 mt-2" onClick={handleAvailableClick.bind(this, practice, userInfo)}>
                                  { practice.available.includes(userInfo.uid)
                                  ? <span><i className='bi bi-hand-thumbs-up-fill text-success'></i> Available ({practice.available.length})</span>
                                  : <span><i className='bi bi-hand-thumbs-up'></i> Available ({practice.available.length})</span>
                                  }
                                </button>

                                <button type="button" className="btn btn-outline-danger me-2 mt-2" onClick={handleUnavailableClick.bind(this, practice, userInfo)}>
                                  { practice.unavailable.includes(userInfo.uid)
                                  ? <span><i className="bi bi-hand-thumbs-down-fill text-danger"></i> Unavailable ({practice.unavailable.length})</span>
                                  : <span><i className="bi bi-hand-thumbs-down"></i> Unavailable ({practice.unavailable.length})</span>
                                  }
                                </button>                                  
                                { practice.isOld === false && userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                <button enabled={showAlert === true ? 'true': 'false'}  type="button" className="btn btn-outline-primary mt-2 float-none float-lg-end" onClick={handleSendPracticeAvailabilityClick.bind(this, practice)}><i className="bi bi-send-fill me-2"></i>Availability Email</button>
                                }

                              </div>
                              }

                              { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                <div className="small text-muted mt-5">Practice ID: {practice.id}</div>
                              }



                            </div>
                          </div>
                        </div>
                        </div>
                      ))}
                      </div>
                      </div>
                    </div>
                  }
              </div>
            </div> 
          </div>
        </div>
      </main>
      { team &&                     
      <div className="modal fade" id="addPractice" tabIndex="-1" aria-labelledby="addPracticeLabel" aria-hidden="true" ref={addPracticeModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addPracticeLabel">Add Practice</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">                  
              <div className="mb-3">
                <label htmlFor="practiceDate"  className="form-label me-2 fw-bold">Practice Date and Time: </label><br />
                <ReactDatePicker id="practiceDate" className="form-control" showIcon showTimeSelect dateFormat="MM/dd/yyyy h:mm aa" selected={practiceDate} onChange={(date) => setPracticeDate(date)} />
              </div>

              <div className="mb-3">
                <label htmlFor="practiceType"  className="form-label me-2 fw-bold">Practice Type: </label>
                <select defaultValue={"Practice Sets (first to RSVP)"} className="form-select" aria-label="Practice Type"  id="practiceType" name="practiceType">
                  <option value="Clinic">Clinic</option>
                  <option value="Clinic & Practice Sets">Clinic &amp; Practice Sets</option>
                  <option value="Practice Sets (first to RSVP)">Practice Sets (first to RSVP)</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="practiceLocation" className="form-label me-2 fw-bold">Practice Location</label>
                <input type="email" className="form-control" id="practiceLocation" defaultValue={team.teamName + " - " + team.address}></input>
              </div>

              <div className="mb-3">
                <label htmlFor="minPlayers"  className="form-label me-2 fw-bold">Min Players: </label>
                <select defaultValue={"4"} className="form-select" aria-label="Minimum Players" id="minPlayers" name="minPlayers">
                  <option value="4">4</option>
                  <option value="8">8</option>
                  <option value="12">12</option>
                  <option value="16">16</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="maxPlayers"  className="form-label me-2 fw-bold">Max Players: </label>
                <select defaultValue={"8"} className="form-select" aria-label="Maximium Players" id="maxPlayers" name="maxPlayers">
                  <option value="4">4</option>
                  <option value="8">8</option>
                  <option value="12">12</option>
                  <option value="16">16</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSavePractice}>Save Practice</button>
            </div>
          </div>
        </div>
      
        { showSavePracticeAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="savePracticeToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>
      }
      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Practice Availability Emails Enqueued!</p>                    
                </div>
            </div>
          </div>
      )}

      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Practices;