import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { getTeam, getTeams, deleteTeam, getPlayers, updateUser } from "./firebaseData";
import "./Teams.css";

function Teams(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerSearch, setPlayerSearch] = useState('');
  const [playersByID, setPlayersByID] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showSaveCaptainsAlert, setShowSaveCaptainsAlert] = useState(false);
  const addCaptainModalRef = useRef();

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      setTeam(await getTeam(userDoc.displayTeam));
    }
    setTeams(await getTeams());
    let playerList = await getPlayers();
    let playerListByID = {};
    playerList.forEach(thePlayer =>{
      playerListByID[thePlayer.id] = thePlayer;
    });
    setPlayers(playerList);
    setFilteredPlayers(playerList);
    setPlayersByID(playerListByID);
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if(!user.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2') return navigate("/");

    console.log('User UID: ', user.uid);

    fetchData();
    console.log(window);

  }, [user, loading]);

  const handlePlayerSearchChange = ((event) => {
    let searchText = event.target.value; 
    let searchExp = new RegExp(searchText, "i");
    setPlayerSearch(searchText)
    
    if (searchText.length > 0) {
      setFilteredPlayers(players.filter((player) => {
        return player.lastName.match(searchExp) || player.firstName.match(searchExp);
      }));    
    } else {
      setFilteredPlayers(players);
    }
  });

  const handleDeleteSelectedTeams = async () => {
    console.log("Handle delete selected...");
    if(window.confirm("Are you sure you want to delete the selected teams from the database?")){
      let teamChecks = document.getElementsByClassName("team-check");
      
      setShowAlert(true);

      for(let i=0; i < teamChecks.length; i++) {
        if(teamChecks[i].checked){
          var teamID = teamChecks[i].id.replace('teamID-', '');
          console.log("Checked: ", teamID);
          await deleteTeam(teamID);
        }
      }

      fetchData();

      let timeout = setTimeout(() => {
        setShowAlert(false);
      }, 1000);
  
    };
  }

  const handleAddCaptain = async (theTeam) => {
    console.log("Add captain to team clicked...", theTeam);

    document.getElementById("theSelectedTeamID").value = theTeam.id
    document.getElementById("addPracticeLabel").innerHTML = "Add Captain to " + theTeam.teamName;

    setPlayerSearch("");
    setFilteredPlayers(players);

    var elements = document.getElementById("captainMultiSelect").options;

    for(var i = 0; i < elements.length; i++){
      elements[i].selected = false;
    }


    const addCaptainModal = addCaptainModalRef.current;
    const bsModal = new window.bootstrap.Modal(addCaptainModal);
    bsModal.show();    

  }

  const handleSaveCaptainSelection = async () => {
    let selectedTeamID = document.getElementById("theSelectedTeamID").value;
    console.log("Add captain dialog save clicked for team ID: ", selectedTeamID)

    setShowSaveCaptainsAlert(true);

    let captainMultiSelect = document.getElementById("captainMultiSelect");
    let selectedCaptains = captainMultiSelect.selectedOptions;
    for (let i = 0; i < selectedCaptains.length; i++){
      console.log("Selected Captain:", selectedCaptains[i].value, selectedCaptains[i].label);
      let selectedPlayer = playersByID[selectedCaptains[i].value];
      if(selectedPlayer.captainsTeam){
        selectedPlayer.captainsTeam.push(selectedTeamID)
      } else {
        selectedPlayer.captainsTeam = [selectedTeamID];
      }
      selectedPlayer.registeredTeams.push(selectedTeamID);
      selectedPlayer.teams.push(selectedTeamID);
      await updateUser(selectedPlayer);
    }

    fetchData();

    let timeout = setTimeout(() => {
      setShowSaveCaptainsAlert(false);
      const addCaptainModal = addCaptainModalRef.current;
      const bsModal = window.bootstrap.Modal.GetInstance(addCaptainModal);
      bsModal.hide();   
    }, 2000);


  }

  //console.log('Rendering Dashboard...');
  let title = "Teams";
  let activeNav = "teams";
  let link = "/teams";
  let thisYear = new Date().getFullYear();

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                    { teams.length === 0 || players.length === 0 && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { teams.length > 0 && players.length > 0 && 
                      <div>
                        <h3>Teams</h3>
                        <div className="my-5">
                          <h5 className="clearfix">Teams ({teams.length})
                          <button type="button" className="btn btn-sm btn-secondary float-end d-none" onClick={handleDeleteSelectedTeams}><i className="bi bi-trash-fill me-2"></i>Delete Checked</button>
                          </h5>
                          <div className="row row-cols-md-2 text-black">
                          { teams.length > 0 && teams.map(theTeam => (
                            <div className="col" key={theTeam.id}>
                            <div className="card my-2">
                              <div className="card-body">
                                <div className="card-title clearfix">
                                {theTeam.year < thisYear &&  
                                <div className="form-check float-end">
                                  <input className="form-check-input team-check" type="checkbox"  id={"teamID-" + theTeam.id} />
                                </div>
                                }
                                  <a className="link-primary float-end" onClick={handleAddCaptain.bind(this, theTeam)} title="Add Captain" alt="Add Captain"><i className="bi bi-plus-circle-fill"></i></a>
                                  <div><strong>Name</strong>: {theTeam.teamName}</div>
                                  { theTeam.teamTitle ? <div><strong>Title</strong>: {theTeam.teamTitle}</div> : <></> }
                                  <div><strong>ID</strong>: {theTeam.id}</div>
                                  <div><strong>Season</strong>: {theTeam.season}</div>
                                </div>
                                <div className="card-text">
                                  <div className="small"><strong>Team Address</strong>:{theTeam.address}</div>
                                  {theTeam.lineCountSingles ? <div className="small mt-2"><strong>Line Count Singles</strong>: {theTeam.lineCountSingles}</div> : <></> }
                                  {theTeam.lineCountDoubles ? <div className="small mb-2"><strong>Line Count Doubles</strong>: {theTeam.lineCountDoubles}</div> : <></> }
                                  {players.map(thePlayer => (
                                    thePlayer.captainsTeam && thePlayer.captainsTeam.includes(theTeam.id) ? <div key={thePlayer.id} className="small">Captain: {thePlayer.firstName} {thePlayer.lastName}</div> : <div className="d-none" key={thePlayer.id}></div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
          </div>
      </main>

      { players.length > 0 &&                    
      <div className="modal fade" id="addCaptain" tabIndex="-1" aria-labelledby="addCaptainLabel" aria-hidden="true" ref={addCaptainModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addPracticeLabel">Add Captain</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">                  
              <input type="hidden" id="theSelectedTeamID" />
              <input type="text" className="form-control" value={playerSearch} onChange={handlePlayerSearchChange} placeholder="Find player by first or last name..." />
              <div className="mb-3">
                <label htmlFor="captainMultiSelect"  className="form-label me-2 fw-bold">Select One or More Captain: </label>
                <select className="form-select" multiple={true} size={5} aria-label="Captain Select"  id="captainMultiSelect" name="captainMultiSelect">
                  {filteredPlayers.map(thePlayer => (
                    <option value={thePlayer.id} key={thePlayer.id}>{thePlayer.firstName} {thePlayer.lastName}</option>
                  ))}
                </select>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveCaptainSelection}>Save Team Captains</button>
            </div>
          </div>
        </div>
      
        { showSaveCaptainsAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="savePracticeToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>
      }



      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span>Processing</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Processing the delete queue...</p>                    
                </div>
            </div>
          </div>
      )}


      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Teams;