import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser } from "./firebaseAuth";
import { getTeam, getTeamsForIDList, updateUser} from "./firebaseData";

import { logout } from "./firebaseAuth";
import "./PageTop.css";

function PageTop(props) {

  const [user, loading] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    //setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      setTeam(await getTeam(userDoc.displayTeam));
    }
    setTeams(await getTeamsForIDList(userDoc.registeredTeams));
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    fetchData();
    console.log(window);

  }, [user, loading]);

  const handleChangeDisplayTeam = (async(event) =>{
    console.log("Change User's Display Team ID to: ", event.target.value);
    userInfo.displayTeam = event.target.value;
    await updateUser(userInfo);
    //fetchData();
    window.location.reload();
  });


  return (

    <div className="navbar-dark bg-dark" style={{ backgroundImage: `url('./assets/svg/components/wave-pattern-light.svg')`}}>
      <div className="container content-space-1 content-space-b-lg-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="d-none d-lg-block">
              <h1 className="h2 text-white">Tennis Team Manager</h1>
            </div>

            { props.breadcrumbs && 
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light mb-0">
              { props.breadcrumbs.map((breadcrumb, index) => (
                breadcrumb.link 
                  ? <li key={index} className="breadcrumb-item"><a href={breadcrumb.link}>{breadcrumb.title}</a></li>
                  : <li key={index} className="breadcrumb-item">{breadcrumb.title}</li>
              ))}
              <span className="ps-4">
                <select onChange={handleChangeDisplayTeam.bind(this)} value={userInfo.displayTeam}>
                { teams.length > 0 && teams.map(theTeam => (
                  <option key={theTeam.id} value={theTeam.id}>{theTeam.teamTitle}</option>                  
                ))}
                </select>                
              </span>
              </ol>
            </nav>
            }
          </div>

          <div className="col-auto text-end">
            <div className="d-none d-lg-block">

                {props.userInfo 
                ?
                  <div className="small text-center">
                    <button className="btn btn-soft-light btn-sm mt-2" onClick={logout}>Log out</button>
                  </div>
                :
                  <div className="small text-center">
                    <a className="btn btn-soft-light btn-sm mt-2" href="/login">Login</a>
                  </div>
                }


            </div>

            <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-default">
                <i className="bi-list"></i>
              </span>
              <span className="navbar-toggler-toggled">
                <i className="bi-x"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

);
}

export default PageTop;
